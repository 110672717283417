import React, { ReactNode, MouseEvent } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type ButtonProps = {
	disabled?: boolean;
	onClick: (event: MouseEvent) => void;
	icon?: IconProp;
	children: ReactNode;
	type?: 'button' | 'reset' | 'submit';
	backgroundColor?: string;
	textColor?: string;
	className?: string;
	ariaLabel: string;
	thin?: boolean;
	primary?: boolean;
};

export default function Button({
	primary = true,
	onClick,
	children,
	disabled = false,
	icon,
	type = 'button',
	backgroundColor,
	textColor,
	className,
	ariaLabel,
	thin,
}: ButtonProps): JSX.Element {
	const styles = primary
		? {
				backgroundColor: backgroundColor,
				color: textColor,
		  }
		: {
				backgroundColor: 'white',
				border: `2px solid ${backgroundColor}`,
				color: backgroundColor,
		  };
	return (
		<button
			className={classnames(
				`btn`,
				{
					disabled,
				},
				thin ? 'h-10' : 'h-12',
				className,
			)}
			style={styles}
			onClick={event => {
				if (disabled) return;
				onClick(event);
			}}
			type={type}
			disabled={disabled}
			aria-label={ariaLabel}
		>
			{children}
			{icon && (
				<FontAwesomeIcon icon={icon} className='ml-2 my-auto' fixedWidth />
			)}
		</button>
	);
}
