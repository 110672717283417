import React from 'react';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import Button from '../Button/Button';

export default function ErrorScreen() {
	return (
		<div id='app'>
			<div
				className='absolute top-0 left-0 w-screen h-screen flex justify-center bg-white'
				style={{
					backgroundImage: `url(/img/ErrorBackground.jpg)`,
					backgroundSize: 'cover',
				}}
			>
				<div className='flex w-full h-full flex-col flex-1 items-center justify-center mx-6 text-slate-700 '>
					<div
						className='flex p-12 bg-grey-100 rounded-3xl flex-col items-center justify-around max-w-4xl'
						style={{
							backgroundColor: 'rgba(243, 244, 246, 0.8)',
						}}
					>
						<h1 className='center text-5xl subheading mt-4 '>
							Hmm, what’s happened here…
						</h1>
						<p className='center mt-4 mb-6'>
							Thank you for your patience! Rest assured this has been reported
							to our development team. Now let’s help you get back to hiring!
						</p>
						<Button
							icon={faChevronLeft}
							onClick={() => {
								window.location.href = '/';
							}}
							ariaLabel='Back To Safety'
						>
							Take me back
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}
