import '../styles/globals.css';
import { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from 'react-query';
import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import * as Sentry from '@sentry/nextjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import ErrorScreen from '../src/components/ErrorScreen/ErrorScreen';

export default function App({ Component, pageProps }: AppProps) {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 120000,
			},
		},
	});

	Sentry.init({
		dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
		environment: process.env.NODE_ENV,
	});

	dayjs.extend(relativeTime);
	dayjs.extend(updateLocale);
	dayjs.updateLocale('en', {
		relativeTime: {
			future: 'in %s',
			past: '%s',
			s: 'Just now',
			m: 'a min',
			mm: '%d mins ago',
			h: 'an hour ago',
			hh: '%dhrs ago',
			d: 'a day ago',
			dd: '%d days ago',
			M: 'a month ago',
			MM: '%d months ago',
			y: 'a year ago',
			yy: '%d years ago',
		},
	});

	return (
		<Sentry.ErrorBoundary fallback={ErrorScreen}>
			<QueryClientProvider client={queryClient}>
				<Component {...pageProps} />
				<ToastContainer limit={5} autoClose={3000} transition={Slide} />
			</QueryClientProvider>
		</Sentry.ErrorBoundary>
	);
}
